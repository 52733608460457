<template>
  <div id="common">
    <div class="page" size="A4" v-for="page in pages" :key="'page' + page">
      <v-row class="pt-2">
        <v-col cols="1">
          <v-avatar size="45">
            <img
              src="https://links.casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>
        </v-col>
        <v-col style="line-height: 10pt">
          <b style="font-size: 10px">Rede Casas das Tintas</b>
          <template v-if="hasAddress">
            <div>
              {{ company.main_address.address }}
              {{ company.main_address.number }},
              {{ company.main_address.district }}

              {{ company.main_address.city }} -
              {{ company.main_address.state }},
              {{ $format.cep(company.main_address.postal_code) }},
            </div>
          </template>
          <div v-if="hasPhones" class="d-flex flex-row">
            <span class="mr-2">Tel:</span>
            <div v-for="(phone, index) in company.phones" :key="index">
              {{ phone.contact }}
              <span v-if="company.phones.length > index + 1">, </span>
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex flex-column text-center align-bottom">
          <b style="font-size: 15px">Valor Pago</b>
          <b style="font-size: 15px">
            {{ $format.moneyBr(bill_receivable_group.net_paid_value) }}
          </b>
        </v-col>
      </v-row>

      <hr class="mt-2 mb-4" />
      <v-row>
        <v-col><h2>Recibo de Pagamento</h2> </v-col>
      </v-row>

      <v-row>
        <v-col>
          Recebemos de
          <b>
            {{ bill_receivable_group.customer.code }} -
            {{ bill_receivable_group.customer.name }}
          </b>
          a Importância de
          {{ $format.decimal(bill_receivable_group.net_paid_value) }}
          <b>({{ $format.extenso(bill_receivable_group.net_paid_value) }}) </b>
          Referente Ao Pagamento das Seguintes Prestações:
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :page="page"
            :headers="headers"
            :items="getItems(page)"
            :items-per-page="itensPerPage"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.document`]="{ item }">
              <span v-if="item.bill_receivable.sale_id">
                {{ item.bill_receivable.sale.number }}
              </span>
              <span v-else>
                {{ item.bill_receivable.document }}
              </span>
            </template>
            <template v-slot:[`item.issue_date`]="{ item }">
              {{ $format.dateBr(item.bill_receivable.issue_date) }}
            </template>
            <template v-slot:[`item.due_date`]="{ item }">
              {{ $format.dateBr(item.bill_receivable.due_date) }}
            </template>
            <template v-slot:[`item.value`]="{ item }">
              <span v-if="item.id">
                {{ $format.decimal(item.bill_receivable.value) }}
              </span>
            </template>
            <template v-slot:[`item.paid_value`]="{ item }">
              <span v-if="item.id">
                {{ $format.decimal(item.paid_value) }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <template v-if="page == pages">
        <v-row class="mt-10">
          <v-col>
            Para maior clareza firmamos o presente.
            <br />
            {{ company.main_address.city }}-{{ company.main_address.state }},
            {{ $format.dateBr(bill_receivable_group.paid_at) }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center text-center">
          <v-col cols="6">
            <v-divider style="border: 0.1px solid black; font-weight: bold" />

            Assinatura
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";

export default {
  props: {
    bill_receivable_group: {
      type: Object,
    },
    company: {
      type: Object,
    },
    hasAddress: {},
    hasPhones: {},
  },

  data() {
    return {
      date: format(new Date(), "dd/MM/yyyy"),
      itensPerPage: 17,
      headers: [
        { text: "Documento", align: "center", value: "document" },
        { text: "Emissão", align: "center", value: "issue_date" },
        { text: "Vencimento", align: "center", value: "due_date" },
        {
          text: "Valor  Original",
          align: "center",
          value: "value",
        },
        {
          text: "Valor  Pago",
          align: "center",
          value: "paid_value",
        },
      ],
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    bill_receivable_payment() {
      return this.bill_receivable_group.bill_receivable_payment;
    },
    itensCount() {
      if (this.bill_receivable_payment) {
        return this.bill_receivable_payment.length;
      }
      return 1;
    },
  },

  methods: {
    saleNumber(bill_receivable) {
      return bill_receivable.sale ? bill_receivable.sale.number : "----";
    },

    getBillReceivable(item) {
      if (item.bill_receivable) {
        console.log(item);
        return item.bill_receivable.value;
      }
    },

    getItems(page) {
      let items = this.bill_receivable_payment.slice(
        (page - 1) * this.itensPerPage,
        page * this.itensPerPage
      );
      if (items.length < this.itensPerPage) {
        for (let i = items.length; i < this.itensPerPage; i++) {
          items.push({
            bill_receivable: {},
          });
        }
      }
      return items;
    },
  },
};
</script>

<style  lang="scss">
@import "@/assets/style/prints/CommonPrinting.scss";
</style>



